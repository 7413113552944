<template>
    
    <HeaderVue @togle-profile-modal="toggleProfileModal()" :isAuthenticated="false"></HeaderVue>
    <main>
        <section class="log-sign" >
            <div class="container action_form-wrap">
                <div class="log-sign_forms">
                    <router-view></router-view>
                </div>
            </div>
            <div class="container container_right-disp">
                <div class="log-sign_right-disp">
                </div>
            </div>
        </section>
    </main>
    <FooterVue></FooterVue>
    <notifications position="top left" />
</template>

<script>
    import HeaderVue from '@/components/common/Header.vue';
    import FooterVue from '@/components/common/Footer.vue';
    export default {
        name: "logsign-layout",
        components: {
            HeaderVue,
            FooterVue,
        },
        data() {
            return {
                baseBackendUrl: process.env.VUE_APP_BASE_URL,
                lang: "UA",
            }
        },
        computed: {
            // lang() {
            //     return this.$route.query.lang
            // }
        },
        methods: {
            changeLocale(locale) {
                this.$i18n.locale = locale
                localStorage.setItem('locale_lang', locale);
            },
            activeLang(locale) {
                let locale_lang = localStorage.getItem('locale_lang')
                if (locale == locale_lang) {
                    return 'active'
                } else {
                    return ''
                }
            },
        },
        mounted() {
            
        }
    }

</script>

<style>
.log-sign {
    display:flex;
    height:100%;
}

.log-sign_forms {
    width:100%;
    display:flex;
    flex-wrap:wrap;
    overflow:visible;
    position:relative;
}

.log-sign_form {
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column;
}

.log-sign .action_form-wrap {
    width:580px;
    max-width:100%;
    background:var(--color-main-white);
    overflow-y:auto;
    overflow-x:hidden;
    padding:32px 24px 8px 28px;
}

.log-sign .container_right-disp {
    width: calc(100% - 580px);
}

.log-sign .log-sign_right-disp {
    height:100%;
    width:100%;
    background-size:cover;
    background-position:center;
    background-image:url('~/public/img/log-sign-placeholder-img.png');
}

.log-sign_form h1 {
    margin-bottom:32px;
    max-width:490px;
}

.signup-form_inputs,.login-form_inputs {
    width:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
}

.log-sign-form_google {
    margin-top:12px;
}

.switch_logsign_wrap {
    margin-top:auto;
    display:flex;
}

.log-sign-form_body {
    width:420px;
    max-width:100%;
    margin:0 0 48px 0;
}

.log-sign-form_body>.login-form,.log-sign-form_body>form {
    width:100%;
}

.log-sign-form_body .form_links,.log-sign-form_body .form_links {
    margin-top:40px;
    display:flex;
    justify-content:space-between;
    overflow:visible;
}

.switch-logsign {
    font-weight:700;
    color:var(--color-main-blue);
}

.log-sign-form_body .form_links a,.log-sign-form_body .form_links a {
    display:block;
    color:#000;
    font-size:14px;
    position:relative;
    margin-bottom:15px;
}

.log-sign-form_body .form_links label,.log-sign-form_body .form_links label {
    color:#000;
    font-size:15px;
}

.form_links input {
    display:none;
}

.agreement_label {
    display:flex;
    margin-bottom:10px;
    align-items:baseline;
}

.agreement_label input {
    padding-top:4px;
}
.btn-create-account {
    margin-top: 25px;
}
@media screen and (max-width: 992px) {
    .log-sign-form_body .form_links,.log-sign-form_body .form_links {
        display:flex;
        flex-direction:column;
    }

    .log-sign-form_body .form_links a,.log-sign-form_body .form_links a {
        width:max-content;
    }

    .agreement_label {
        align-items:flex-start;
    }

    .agreement_label p {
        font-size:12px;
        line-height:14px;
    }

    .agreement_label a {
        font-size:12px;
    }
}

@media screen and (max-width: 800px) {
    .log-sign .action_form-wrap {
        width: 450px;
    }
    .log-sign h1 {
        font-size: 36px;
    }
    .container.container_right-disp {
        width: calc(100% - 450px);
    }

}

@media screen and (max-width: 580px) {
    .log-sign .action_form-wrap {
        width:100%;
    }
    .log-sign>.container:last-of-type {
        width: 0;
    }
}

</style>