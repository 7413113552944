<template>
    <HeaderVue @togle-payment-popup="togglePaymentPopup()" @togle-profile-modal="toggleProfileModal()" :isAuthenticated="true"></HeaderVue>
    <main>
        <section class="main-board user-board">
            <div class="container">
                <div class="user_control">
                    <div class="user-board_back_to_workboard_wrap">
                        <router-link to="../" class="back-to-work-board">{{ $t('userBackToQuiz') }}</router-link>
                    </div>
                    <nav class="user-board_menu">
                        <div class="one_user_link_wrap">
                            <router-link class="billing" to="../billing">{{ $t('navBillings') }}</router-link>
                        </div>
                        <div class="one_user_link_wrap one_user_link_wrap_partnership">
                            <router-link class="partnership" to="../partnership">{{ $t('navPartnership') }}</router-link>
                        </div>
                        <div class="one_user_link_wrap">
                            <router-link class="profile" to="../profile">{{ $t('navProfile') }}</router-link>
                        </div>
                    </nav>
                </div>
                <div class="user_body">
                    <router-view ref="userBoardRouterView" @togle-payment-popup="togglePaymentPopup" @togle-unsubscribe-popup="toggleUnsubscribePopup" :cancelSubscribeIndex = cancelSubscribeIndex></router-view>
                </div>
            </div>
        </section>
    </main>
    <FooterVue></FooterVue>
    <modal-payment-popup v-show="isOpenedPaymentPopup" @togle-payment-popup="togglePaymentPopup()"/>
    <modal-unsubscribe-popup ref="unsubscribePopup" v-show="isOpenedUnsubscribePopup" :unsubscribeTarifData="unsubscribeTarifData" @togle-unsubscribe-popup="toggleUnsubscribePopup" @complete-cancel-subscription="completeCancelSubscription" />
    <transition name="fade">
        <ModalProfileAction v-show="isOpenedProfileModal" @togle-profile-modal="toggleProfileModal()"/>
    </transition>
    <notifications position="top right" />
</template>

<script>
    import axios from "axios"
    // import WorkboardView from '@/views/WorkboardView.vue'
    import HeaderVue from '@/components/common/Header.vue'
    import FooterVue from '@/components/common/Footer.vue'
    import ModalPaymentPopup from '@/components/common/ModalPaymentPopup.vue'
    import ModalUnsubscribePopup from '@/components/common/ModalUnsubscribePopup.vue'
    import ModalProfileAction from '@/components/common/ModalProfileAction.vue'
    export default {
        name: "main-layout",
        components: {
            HeaderVue,
            FooterVue,
            ModalPaymentPopup,
            ModalUnsubscribePopup,
            ModalProfileAction,
        },
        data() {
            return {
                baseBackendUrl: process.env.VUE_APP_BASE_URL,
                baseUserIdent: process.env.VUE_APP_USER_IDENT,
                isOpenedPaymentPopup: false,
                isOpenedUnsubscribePopup: false,
                isOpenedProfileModal: false,
                unsubscribeTarifData: {},
                cancelSubscribeIndex: "",
            }
        },
        computed: {
        },
        methods: {
            changeLocale(locale) {
                this.$i18n.locale = locale
                localStorage.setItem('locale_lang', locale);
                this.updateUserLang(this.$i18n.locale)
                this.mountedLang(this.$i18n.locale)
            },
            updateUserLang(locale) {
                var data = JSON.stringify({
                    query: `mutation {
                        updateUser(
                            id: "` + this.userInfo.id + `",
                            interface_lang: "${locale}",
                        )
                        {
                            id
                            interface_lang
                        }
                    }`,
                    variables: {}

                });

                var config = {
                    method: 'post',
                    url: this.baseBackendUrl + '/graphql/',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(config)
                    .then(() => {
                        
                    })
                    .catch (error => {
                        this.$notify({
                            title: 'Помилка в оновлені даних: "' + this.userInfo.email,
                            type: 'error'
                        });
                        console.log(error);
                    })
            },
            mountedLang(interfaceLang) {
                var htmlElement = document.getElementsByTagName('html')[0]
                htmlElement.setAttribute('lang', interfaceLang);
            },
            activeLang(locale) {
                let locale_lang = this.$i18n.locale
                if (locale == locale_lang) {
                    return 'active'
                } else {
                    return ''
                }
            },
            togglePaymentPopup() {
                this.isOpenedPaymentPopup = !this.isOpenedPaymentPopup
            },
            completeCancelSubscription() {
                this.$notify({
                    title: this.$t('subscriptionCanceledTitle'),
                    type: 'success'
                });
                this.cancelSubscribeIndex = "0"
            },
            toggleUnsubscribePopup(id) {
                console.log(id)
                this.isOpenedUnsubscribePopup = !this.isOpenedUnsubscribePopup
                if (id) {
                    this.unsubscribeTarifData = {id: id}
                }
            },
            toggleProfileModal() {
                this.isOpenedProfileModal = !this.isOpenedProfileModal
            },
            goToProfile() {
                this.$router.push('profile')
            },
            logout() {
                var config = {
                    method: 'post',
                    url: this.baseBackendUrl + '/logout',
                    headers: {
                        'Accept': 'application/json'
                    },
                };
                axios(config)
                    .then(() => {
                        localStorage.setItem('isLogin', '0')
                        // eslint-disable-next-line
                        HelpCrunch('logout');
                        this.$router.push({ name: 'login-view' })
                        this.deleteCookie('adsquiz_session')
                        this.deleteCookie('XSRF-TOKEN')
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            changeLocaleAfterLogin(interface_lang) {
                this.$i18n.locale = interface_lang
            },
            setCookie(name, value, options = {}) {
                options = {
                    path: '/',
                    // при необходимости добавьте другие значения по умолчанию
                    ...options
                };

                if (options.expires instanceof Date) {
                    options.expires = options.expires.toUTCString();
                }

                let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

                for (let optionKey in options) {
                    updatedCookie += "; " + optionKey;
                    let optionValue = options[optionKey];
                    if (optionValue !== true) {
                        updatedCookie += "=" + optionValue;
                    }
                }

                document.cookie = updatedCookie;
            },
            deleteCookie(name) {
                this.setCookie(name, "", {
                    'max-age': -1
                })
            },
        },
        mounted() {
            this.isOpenedProfileModal = false
            this.mountedLang(this.$i18n.locale)
        },
        beforeRouteUpdate() {
            this.isOpenedProfileModal = false
        },
        watch: {
        }
    }

</script>

<style lang="css">
.user-board>.container {
    margin: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
}
.user-board .user_body {
    background: var(--color-main-white);
}
.user-board .user_body>.main-board {
    display: flex;
    flex-direction: column;
    background: var(--color-main-white);
}
.user_control {
    height: 100%;
    width: 356px;
    border-radius: 0;
    padding: 16px 24px 16px 28px;
}

.user_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: calc(100% - 356px);
    border-radius: 10px;
    padding: 24px 28px 20px 24px;
    margin: 0 auto;
    position: relative;
    z-index: 100;
}
.user-board_menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
}
.one_user_link_wrap {
    width: 100%;
    padding: 8px 0;
}
.one_user_link_wrap_partnership {
    display: none;
}
.user-board_menu a {
    display: block;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
}
.profile-board>.container {
    margin: 0;
    width: 100%;
    max-width: 100%;
}
.b-p-p-board_header {
    width: 100%;
    margin-bottom: 16px;
}
.b-p-p-board_body {
    width: 100%;
    max-height: 100%;
}
.b-p-p-board_prof-info {
    width: 100%;
    padding: 24px 24px 48px 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.p-b_p-i_section_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.p-b_p-i_review {
    width: 250px;
}
.p-b_p-i_review_header {
    margin-bottom: 4px;
}
.p-b_p-i_review_paragraph {
    margin-bottom: 16px;
}
.p-b_p-i_review_paragraph:last-of-type {
    margin-bottom: 0;
}
.p-b_p-i_inputs {
    width: calc(100% - 290px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
}
.p-b_wrap-item {
    width: calc(50% - 12px);
}
.b-p_general_point-wrap {
    background-color: var(--color-main-light-gray)!important;
}
.p-b_input-wrap {
    width: 100%;
}
.p-b_input-wrap label {
    width: 100%;
    margin-bottom: 4px;
}
.p-b_input-wrap .p-b_profile_input, 
.p-b_input-wrap .p-b_profile_select{
    width: 100%;
}
.p-b_p-i_buttons {
    display: flex;
    gap: 16px;
    margin-left: 290px;
}
.btn-save_user {
    width: auto!important;
}
.p-b_p-i_promo {
    padding-top: 20px;
}
.btn-save_promo {
    height: 48px!important;
}

@media screen and (max-width: 1100px) {
    .user_control {
        width: 280px;
    }
    .user_body {
        width: calc(100% - 280px);
    }
    .p-b_p-i_review {
        width: 200px;
    }
    .p-b_p-i_inputs {
        width: calc(100% - 224px);
    }
    .p-b_p-i_buttons {
        margin-left: 224px;
    }
}

@media screen and (max-width: 920px) {
    .user_control {
        width: 240px;
    }
    .user_body {
        width: calc(100% - 240px);
    }
    .p-b_p-i_review {
        width: 156px;
    }
    .p-b_p-i_inputs {
        width: calc(100% - 180px);
    }
    .p-b_p-i_buttons {
        margin-left: 180px;
    }
}

@media screen and (max-width: 860px) {
    .b-p-p-board_prof-info {
        gap: 24px;
    }
    .p-b_p-i_inputs {
        gap: 4px;
    }
    .p-b_wrap-item {
        width: 100%;
    }
    .btn-save_user {
        width: 100%!important;
    }
}

@media screen and (max-width: 760px) {
    .user_control {
        width: 140px;
        padding: 16px;
    }
    .user_body {
        width: calc(100% - 140px);
        padding: 16px;
    }
    .p-b_p-i_section_wrap {
        flex-direction: column;
        gap: 24px;
    }
    .p-b_p-i_review {
        width: 100%;
    }
    .p-b_p-i_inputs {
        width: 100%;
    }
    .p-b_p-i_buttons {
        flex-direction: column;
        margin-left: 0;
    }
}

.billing-board .b-p-p-board_body {
    height: 100%;
}
/* .board-biling_page-wrap {
    height: 100%;
} */
.non_bill_wrap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.t-billing_body {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
}
.t-billing_table-header {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.t-billing_table_body {
    height: calc(100% - 40px);
}
.t-billing_th {
    display: flex;
    align-items: center;
    width: calc((100%) / 4);
    padding: 8px 16px;
}
.t-billing_th.t-billing_th_date {
    width: calc(((100%) / 4) - 8%);
}
.t-billing_th.t-billing_th_cost {
    width: calc(((100%) / 4) - 12%);
}
.t-billing_th.t-billing_th_id {
    width: calc(((100%) / 4) + 15%);
}
.t-billing_th.t-billing_th_payment_method {
    width: calc(((100%) / 4) + 5%);
}
.t-billing_th p {
    display: block;
}
.t-billing_th_date {
    display: flex;
}
.t-billing_tr {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.t-billing_tr>div {
    display: block;
    width: calc((100%) / 4);
    padding: 20px 14px;
    background-color: transparent;
}
.t-billing_tr>div.billing_date {
    width: calc(((100%) / 4) - 8%);
}
.t-billing_tr>div.billing-price {
    width: calc(((100%) / 4) - 12%);
}
.t-billing_tr>div.billing-id {
    width: calc(((100%) / 4) + 15%);
}
.t-billing_tr>div.billing-id p {
    overflow: hidden;
    text-overflow: ellipsis;
}
.t-billing_tr>div.method_wrap {
    width: calc(((100%) / 4) + 5%);
}
.t-billing_tr>div.method_wrap p {
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .t-billing_tr>div.payment_action {
    width: 64px;
    padding: 16px;
} */
@media screen and (max-width: 820px) {
.t-billing_tr>div {
    padding: 4px 8px;
}
/* .t-billing_tr>div.billing_date {
    width: calc(((100% - 64px) / 4) - 8%);
}
.t-billing_tr>div.billing-price {
    width: calc(((100% - 64px) / 4) - 12%);
}
.t-billing_tr>div.billing-id {
    width: calc(((100% - 64px) / 4) + 15%);
}
.t-billing_tr>div.method_wrap {
    width: calc(((100% - 64px) / 4) + 5%);
} */
}
.b-b_bonuses {
    width: 100%;
}
.promocode_input-wrap {
    display: flex;
    align-items: center;
    width: 100%;
}

.b-b_bonuses input[type="text"] {
    width: 100%;
}

.b-b_bonuses input[type="button"] {
    text-align: center;
    cursor: pointer;
}



@media screen and (max-width: 960px) {
    .billing-date {
        font-size: 12px;
    }
    .billing-id_number {
        font-size: 12px;
    }
    .method_wrap p {
        font-size: 12px;
    }
}

@media screen and (max-width: 442px) {
    .user_control {
        width: 64px;
        padding: 16px;
    }
    .user_body {
        width: calc(100% - 64px);
        padding: 16px 0;
        background-color: var(--color-main-white);
        border-radius: 0;
    }
    .user-board .container {
        max-width: 100%;
    }
    .user-board .billing-board {
        padding: 16px;
    }
    .user_body .user-board {
        background-color: var(--color-main-white);
    }
    .user-board_menu a {
        overflow: hidden;
        font-size: 0;
        height: 32px;
        background-size: 70%;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .user-board_menu a.billing {
        background-image: url('~/public/img/icons/credit-card.svg');
    }
    .user-board_menu a.profile {
        background-image: url('~/public/img/icons/profile_settings.svg');
    }
    .b-p-p-board_header {
        padding: 0 16px;
    }
    .user-board_back_to_workboard_wrap {
        height: 24px;
    }
    .user-board_back_to_workboard_wrap .back-to-work-board {
        height: 100%;
        font-size: 0;
        background-position: center;
    }
    .b-p-p-board_prof-info {
        padding: 16px;
        border: none!important;
    }
    
}


</style>