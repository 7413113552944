<template>
    <div class="language-selector">
        <div class="selected-language" @click="toggleDropdown">
            <p>{{ activeLanguage }}</p>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 7L8.00001 11L12 7" stroke="#1C1C1C" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        
        <transition name="fast-fade">
            <div class="language-dropdown" v-if="isDropdownOpen">
                <div v-for="locale in availableLanguages"
                :key="locale"
                :class="['language-option', { active: locale === activeLanguage }]"
                @click="changeLocale(locale)">
                    {{ locale }}
                </div>
            </div>
        </transition>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        isDropdownOpen: false,
        myLangOrder: ["UA", "RU", "EN", "DE"]
      };
    },
    computed: {
        activeLanguage() {
            return this.$i18n.locale;
        },
        availableLanguages() {
          // Тут, кажется ошибка в логике. Лишние действия. Нужно переделать
            let languages = this.$i18n.availableLocales.filter((locale) => locale !== this.currentLocale);
            languages.sort((a, b) => {
              const aIndex = this.myLangOrder.indexOf(a);
              const bIndex = this.myLangOrder.indexOf(b);
              return aIndex - bIndex;
            });
            const index = languages.indexOf(this.activeLanguage);
            if (index > -1) {
                languages.splice(index, 1);
            }
            languages.unshift(this.activeLanguage);
            return languages;
        },
    },
    methods: {
      toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
      },
      changeLocale(locale) {
        this.$i18n.locale = locale;
        this.isDropdownOpen = false;
        this.$emit('change-locale', locale);
      },
    },
  };
  </script>
  
  <style scoped>
  .language-selector {
    position: relative;
    display: inline-block;
  }
  
  .selected-language {
    padding: 8px 8px 8px 8px;
    background-color: #FFF;
    border: none;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.08); */
    border-radius: 8px;
  }
  
  .selected-language:hover {
    background-color: #F5F5F5;
  }
  
  .selected-language svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    object-fit: contain;
  }

  .language-dropdown {
    position: absolute;
    top: 0%;
    left: 0;
    display: none;
    background-color: #FFF;
    padding: 0;
    border: none;
    box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.08);
    border-radius: 8px;
    z-index: 1;
  }
  
  .language-selector:hover .language-dropdown {
    display: block;
    overflow: hidden;
  }
  
  .language-option {
    padding: 8px 28px 8px 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
  }
  
  .language-option:hover {
    background-color: #F5F5F5;
    color: #3749C0;
  }

  .language-option:first-child,
  .language-option:first-child:hover {
    color: #808080;
  }
  
  .language-option.active {
    font-weight: 400;
  }
  </style>
  