<template>
    <header>
        <div class="container">
            <div class="header_links">
                <a class="header_logo" href="#"><img src="img/icons/dashboard_logo.svg" alt="adsquiz site logo"></a>
            </div>
            <div class="header_buttons">
                <input type="button" class="header_admin-button" value="admin">
            </div>
        </div>
    </header>
    <main>
        <section class="main-board adm-board dashboard">
            <div class="container">
                <router-view></router-view>
            </div>
        </section>
    </main>
    <footer>
        <section>
            <div class="container">
                <div class="footer_left">
                    <img src="img/icons/adsquiz_footer-logo.svg" alt="adsquiz footer logo">
                    <a href=""> © 2022-2023</a>
                </div>
                <div class="footer_right">
                    <a href="mailto:info@adsquiz.io">
                        <img src="img/icons/mail_footer.svg" alt="Mail">
                        info@adsquiz.io
                    </a>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
    // import DashboardView from '@/views/DashboardView.vue'
    export default {
      name: "admin-layout",
      components: {
          // WorkboardView
      }
    }

</script>

<style>
.header_admin-button {
      background: #000;
      border: 1px solid #000;
      border-radius: 10px;
      color: #fff;
      padding: 10px 21px;
    }
    
    .adm-b_header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
    }
    
    .back-to-dashboard-board_wrap {
      width: 180px;
    }
    
    .back-to-dashboard-btn {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding: 20px 8px 20px 20px;
      background-image: url("../assets/img/icons/back-solid.svg");
      background-position: 10px center;
      background-repeat: no-repeat;
      background-size: 12px;
      transition: all 1s;
    }
    
    .back-to-dashboard-btn:hover {
      transition: all 0.6s;
      margin-left: -25px;
      transition-duration: 0.6s;
      border-radius: 100px 20px 20px 100px;
      /* animation: backbtnmove 0.5s cubic-bezier(0, -0.28, 0.62, 1.21) infinite alternate; */
    }
    
    .adm-b-h_menu,
    .adm-b-h_search,
    .adm-b-h_count-res {
      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      padding: 18px 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .adm-b-h_menu>div {
      margin-right: 30px;
    }
    
    .adm-b-h_menu>div:last-of-type {
      margin-right: 0px;
    }
    
    .adm-b-h_menu h5 {
      font-weight: 700;
      color: #000;
      margin-right: 10px;
    }
    
    .adm-b-h_menu input {
      padding: 0 30px;
      /* font-size: 14px; */
      color: #8C979F;
      font-size: 13px;
    }

    a {
      font-size: 13px;
      display: block;
    }
    
    .adm-b-h_menu input:hover,
    .adm-b-h_menu input:focus,
    .adm-b-h_menu input:active {
      color: #000;
      transform: scale(1.1);
    }
    
    .adm-b-h_menu input.active {
      font-weight: 700;
      color: #000;
      transform: none;
    }
    
    .adm-b-h_body {
      margin-top: 40px;
      display: flex;
    
    }
    
    .adm-b-h_filter {
      display: flex;
      width: max-content;
    }
    
    .adm-b-h_filter input {
      padding: 0 5px;
      background: rgba(255, 255, 255, 0);
      border: 1px solid rgba(255, 255, 255, 0);
      box-shadow: none;
    }
    
    .adm-b-h_filter input:hover,
    .adm-b-h_filter input:focus,
    .adm-b-h_filter input:active {
      box-shadow: none;
    }
    
    .adm-b-h_pro-duration {
      display: flex;
    }
    
    .adm-b-h_pro-duration h5 {
      vertical-align: middle;
    }
    
    .adm-b-h_pro-duration .ui-selectmenu-button.ui-button {
      text-align: left;
      white-space: nowrap;
      width: 100px;
      border:none;
      padding: 3px 10px;
      background: #fff;
    }
    
    .ui-selectmenu-menu .ui-menu {
      border-radius: 5px;
    }
    .adm-b-h_search input {
      border:none;
      padding: 3px 10px;
      background: #fff;
      width: 100px;
    }
    
    .adm-b-h_search input:active,
    .adm-b-h_search input:focus,
    .adm-b-h_search input:hover {
      outline: none;
    }
    
    .adm-b-h_search button  {
      padding: 0px;
      width: 24px;
      height: 24px;
      border: none;
      background: rgba(255, 255, 255, 0);
    }
    
    .adm-b-h_search button:hover  {
      cursor: pointer;
      transform: scale(1.1);
    }
    
    .adm-b-h_search button img {
      object-fit: contain;
      object-position: center center;
    }
    
    .adm-b-h_count-res {
      display: flex;
      color: #E6E6E6;
    }
    
    .adm-b-h_count-res span {
      margin-left: 6px;
    }
    
    
    
    
    @media screen and (max-width: 1400px) {
      .adm-b_header * {
        font-size: 12px;
      }
      .adm-b-h_menu input {
        font-size: 12px;
      }
      .adm-b-h_search input {
        width: 80px;
      }
      .adm-b-h_search button {
        padding: 0px;
        width: 20px;
        height: 20px;
      }
    }
</style>